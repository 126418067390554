import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import { parseISO, format } from 'date-fns';
import TagActive from 'components/TagActive';
import Pagination from "react-js-pagination";
import './style.css';
import BtnIcon from 'components/BtnIcon';

export default function Assessments() {

    const [assessments, setAssessments] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);




    useEffect(() => {

        const loadAssessments = async () => {
            try {
                const response = await api.get(`/admin/assessments?page=${page.toString()}`);
                setAssessments(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);

            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os assessmentes.', type: 'error'});
                console.log(error)
            }
        }
        loadAssessments();
    }, [page])

    return (
        <Page name="assessments" loading={isLoading}>
            <h1>Avaliações | <small> <b>{paginate.total}</b> cadastrado(s)</small></h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Paciente</th>
                            <th>Fisioterapeuta</th>
                            <th>Iniciada Em</th>
                            <th>Testes</th>
                            <th>Status</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assessments.map((assessment, index) => (
                                <tr key={index}>
                                    <td><div className="trInfo"><Avatar image={assessment.patient[0]?.picture} name={assessment.patient[0]?.name} size={24} /><span>{`${assessment.patient[0]?.name.slice(0, 12)}...`}</span></div></td>
                                    <td><div className="trInfo"><Avatar image={assessment.user[0]?.avatar} name={assessment.user[0]?.name} size={24} /><span>{`${assessment.user[0]?.name.slice(0, 12)}...`}</span></div></td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(assessment.createdAt), 'dd/MM/yy')}</td>
                                    <td><span className="badge">{assessment.testsIds.length}</span></td>
                                    <td><TagActive label={assessment.completed ? 'Completo' : 'Incompleto'} active={assessment.completed} /></td>
                                    <td className="areaIcons">
                                        <a href={`${api.baseUrl}/reports/default/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="link" size={18} /></a>
                                        <a href={`${api.baseUrl}/reports/print/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="file-text" size={18} /></a>
                                        <a href={`${api.baseUrl}/reports/pdf/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="file-pdf" size={18} /></a>
                                    </td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={true}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />

        </Page>
    )
}
