import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import logo from 'assets/images/logo.png';
import {getAuthUser, Logout} from 'services/auth';
import Avatar from 'components/Avatar';
import {useDispatch, useSelector } from 'react-redux';
import Loading from 'components/Loading';

import './style.css';


export default function Page(props){

  const {name, loading} = props;

  const [showMenu, setShowMenu] = useState(false)
  const [userLogged, setUserLogged] = useState({});

  const [roles, setRoles] = useState({});
  
  const logged  = useSelector(state => state.loginState.userLogged);
  
  const dispatch = useDispatch();


  const logout = () => {
    dispatch({type: 'SET_LOGGED', isLogged: false})
    Logout();
  }
  
  useEffect(() => {
    const getUserLogged = async () => {
        const user = await getAuthUser()
        setUserLogged(user);
        setRoles(user.rolesAdmin);
      }
    getUserLogged()
  }, [logged])

  return (
    <>
        <div className="header">
            <img src={logo} alt="Phast" />

            <div className="areaSubMenu">
                <div className="areaProfile">
                    <div className="infoProfile">
                        <p>{userLogged?.name} <br/> <small>{userLogged?.email}</small></p>
                    </div> 
                    <Avatar image={userLogged?.avatar} name={userLogged?.name} size={40} />
                </div>

                <div className="submenu animated fadeIn">
                    <Link to="/my-account"><li className={name === 'my-account' ? 'active' : ''}><span>Minha Conta</span><i className='bx bxs-user-detail'></i></li></Link>
                    <Link to="/update-password"><li className={name === 'update-password' ? 'active' : ''}><span>Alterar Senha</span><i className='bx bx-lock-open-alt'></i></li></Link>
                    <a href="/" onClick={logout}><li className={name === 'logout' ? 'active' : ''}><span>Logout</span><i className='bx bx-log-out' ></i></li></a>
                </div>

            </div>
            
            <div className="btnMenu animated fadeIn" onClick={() => setShowMenu(!showMenu)}>
                <i className='bx bx-menu'></i>
            </div>
        </div>

        {
            showMenu && 
            <div className="menuMobi animated fadeIn">
                <div className="contentAside">
                    <ul>
                        { roles.dashboard && <Link to="/dashboard"><li className={name === 'dashboard' ? 'active' : ''}><i className='bx bxs-dashboard'></i> <span>Dashboard</span></li></Link>}
                        { roles.clients && <Link to="/clients"><li className={name === 'clients' ? 'active' : ''}><i className='bx bx-user'></i> <span>Clientes</span></li></Link>}
                        { roles.patients && <Link to="/patients"><li className={name === 'patients' ? 'active' : ''}><i className='bx bx-cabinet'></i> <span>Pacientes</span></li></Link>}
                        { roles.assessments && <Link to="/assessments"><li className={name === 'assessments' ? 'active' : ''}><i className='bx bx-task'></i> <span>Avaliações</span></li></Link>}
                        { roles.typesAssessments && <Link to="/assessment-types"><li className={name === 'assessment-types' ? 'active' : ''}><i className='bx bx-box'></i> <span>Tipos de Avaliação</span></li></Link>}
                        {/* { roles.pagarme && <Link to="/pagarme"><li className={name === 'pagarme' ? 'active' : ''}><i className='bx bx-wallet' ></i> <span>PagarMe</span></li></Link>} */}
                        { roles.faq && <Link to="/questions"><li className={name === 'questions' ? 'active' : ''}><i className='bx bx-help-circle' ></i> <span>FAQ</span></li></Link>}
                        { roles.logs && <Link to="/logs"><li className={name === 'logs' ? 'active' : ''}><i className='bx bx-task' ></i> <span>Logs</span></li></Link>}
                        { roles.users && <Link to="/settings"><li className={name === 'users' ? 'active' : ''}><i className='bx bx-group'></i> <span>Usuarios</span></li></Link>}
                        { roles.settings && <Link to="/settings"><li className={name === 'settings' ? 'active' : ''}><i className='bx bx-cog'></i> <span>Configurações</span></li></Link>}
                        { roles.profile && <Link to="/profile"><li className={name === 'profile' ? 'active' : ''}><i className='bx bxs-user-detail'></i> <span>Meus Dados</span></li></Link>}
                        { roles.logout && <Link to="/logout"><li className={name === 'profile' ? 'active' : ''}><i className='bx bx-log-out'></i> <span>Logout</span></li></Link>}
                    </ul>
                </div>
            </div>
        }

        <div className="pagina">
            <div className="aside">
                <div className="contentAside">
                    <ul>
                        {roles.dashboard && <Link to="/dashboard"><li className={name === 'dashboard' ? 'active' : ''}><i className='bx bxs-dashboard'></i> <span>Dashboard</span></li></Link>}
                        {roles.clients && <Link to="/clients"><li className={name === 'clients' ? 'active' : ''}><i className='bx bx-user'></i> <span>Clientes</span></li></Link>}
                        {roles.patients && <Link to="/patients"><li className={name === 'patients' ? 'active' : ''}><i className='bx bx-cabinet'></i> <span>Pacientes</span></li></Link>}
                        {roles.assessments && <Link to="/assessments"><li className={name === 'assessments' ? 'active' : ''}><i className='bx bx-task'></i> <span>Avaliações</span></li></Link>}
                        {roles.typesAssessments && <Link to="/assessment-types"><li className={name === 'assessment-types' ? 'active' : ''}><i className='bx bx-box'></i> <span>Tipos de Avaliação</span></li></Link>}
                        
                        {/* Menu PagarMe */}
                        {/* {roles.pagarme && <li className={subMenuPagarme || name === 'pagarme' ? 'active' : ''} onClick={()=>setSubMenuPagarme(!subMenuPagarme)}><i className='bx bx-wallet' ></i><span>PagarMe</span></li>}
                        {subMenuPagarme &&
                            <ul className="dropMenu animated fadeIn">
                            <Link to="/pagarme/plans"><li className={name === 'plansPagarme' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Planos</span></li></Link>
                            <Link to="/pagarme/signatures"><li className={name === 'plansPagarme' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Aassinaturas</span></li></Link>
                            </ul>
                        } */}
                        
                        { roles.plans && <Link to="/plans"><li className={name === 'plans' ? 'active' : ''}><i className='bx bx-store'></i> <span>Planos</span></li></Link>}
                        { roles.faq && <Link to="/questions"><li className={name === 'questions' ? 'active' : ''}><i className='bx bx-help-circle' ></i> <span>FAQ</span></li></Link>}
                        { roles.logs && <Link to="/logs"><li className={name === 'logs' ? 'active' : ''}><i className='bx bx-task' ></i> <span>Logs</span></li></Link>}
                        { roles.users && <Link to="/users"><li className={name === 'users' ? 'active' : ''}><i className='bx bx-group'></i> <span>Usuários</span></li></Link>}
                        { roles.settings && <Link to="/settings"><li className={name === 'seetings' ? 'active' : ''}><i className='bx bx-cog'></i> <span>Configurações</span></li></Link>}


                    </ul>
                </div>
            </div>


            <div className="content animated fadeIn">
                { loading ? <Loading /> : props.children}
            </div>
        </div>
    </>
  );
}