import React, { useState, useEffect } from "react";

import Page from "components/Templates/Page";
import Avatar from "components/Avatar";
import BtnTitle from "components/BtnTitle";

import { Switch } from "react-switch-input";

import { useHistory, useParams } from "react-router-dom";

import api from "services/api";

import "./style.css";

export default function ShowClient() {
  const history = useHistory();
  const { _id } = useParams();

  const [client, setClient] = useState({});
  const [active, setActive] = useState(false);

  const [, setPlans] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      setIsLoading(true);
      let response = await api.get(`/admin/clients/${_id}`);
      let client = response.data;
      setClient(client);
      setActive(client.active);
      setIsLoading(false);
    };
    loadUser();
  }, [_id]);

  useEffect(() => {
    const loadPlans = async () => {
      const response = await api.get(`/admin/plans`);
      setPlans(response.data);
    };
    loadPlans();
  }, []);

  return (
    <Page name="my-account" isLoading={isLoading}>
      <h1>
        Cliente
        <BtnTitle
          label="Voltar"
          iconName="chevron-left"
          handleOnClick={() => history.goBack()}
        />
      </h1>

      <div className="areaInfo">
        <div className="container cardAvatar">
          <div className="areaAvatar">
            <Avatar name={client?.name} image={client?.avatar} size={100} />
          </div>
        </div>

        <div className="areaInformacoes">
          <div className="informacoes">
            <div className="container">
              <h3>Informações Básicas</h3>
              <div className="areaColunas">
                <h4>
                  <small>Nome:</small>
                  <br />
                  {client?.name}
                </h4>
                <h4>
                  <small>Email:</small>
                  <br />
                  {client?.email}
                </h4>
                <h4>
                  <small>Telefone:</small>
                  <br />
                  {client?.phone}
                </h4>
              </div>
            </div>

            <div className="container">
              <h3>Dados da Conta</h3>
              <table className="uk-table uk-table-striped uk-table-small uk-table-middle">
                <tbody>
                  <tr>
                    <td width="90%">A conta está ativa?</td>
                    <td>
                      <Switch
                        name={"active"}
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Plano Contratado</td>
                    <td>
                      <strong>
                        {client?.plan?.name ? client.plan.name : "---"}
                      </strong>
                    </td>
                  </tr>
                  {client?.plan?.name && (
                    <tr>
                      <td>Status da assinatura</td>
                      <td>
                        <strong>{client.subscription.status}</strong>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Tempo de teste expira em:</td>
                    <td>01/12/2020</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
