import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Pagination from "react-js-pagination";
import BtnTitle from 'components/BtnTitle';
import { useHistory, Link } from 'react-router-dom';

export default function Settings() {
    const history = useHistory();
    const [settings, setSettings] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);


    useEffect(() => {
        const loadSettings = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/settings?page=${page.toString()}`);
                setSettings(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);
            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os settings.', type: 'error'});
            }
        }
        loadSettings();
    }, [page])

    return (
        <Page name="settings" loading={isLoading}>
            <h1>Configurações
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/settings/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Acessar</th>
                            <th>Idioma</th>
                            <th>Criado em:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            settings.map((setting, index) => {

                                return (
                                    <tr key={index}>
                                        <td><Link to={`/settings/edit/${setting._id}`}>Visualizar Configurações</Link></td>
                                        <td>{setting.language}</td>
                                        <td><i className='bx bx-calendar'></i>{format(parseISO(setting.createdAt), 'dd/M/Y')}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={true}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />


        </Page>
    )
}
