import React, {useState, useEffect} from 'react';
import ImageUploading from 'react-images-uploading';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import Avatar from 'components/Avatar';
import BtnLink from 'components/BtnLink';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import FieldSelect from 'components/FieldSelect';
import Button from 'components/Button';
import CardTest from 'components/CardTest';
import { Switch } from 'react-switch-input';
import api from 'services/api';
import { useHistory, useParams } from "react-router-dom";

import './style.css';

export default function EditAssessmentType() {

    let { _id } = useParams();

    const history = useHistory();
    
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [picture, setPicture] = useState('');
    const [testsIds, setTestsIds] = useState([]);
    const [selectedTests, setSelectedTests] = useState([]);
    const [tests, setTests] = useState([]);
    const [selectTest, setSelectTest] = useState({});
    const [lesion, setLesion] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({message: '', type: ''});
    
    

    useEffect(() => {
        const loadTests = async () => {
            try{
                const response = await api.get('/tests?limit=40');
                setTests(response.data);
                setIsLoading(false);        
            }catch(error){
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os clientes.', type: 'error'});
                console.log(error)
            }
        }
        loadTests();
    }, [])



   useEffect(() => {
        const addTest = () => {
            const test = tests[selectTest];
            if(test && selectedTests.indexOf(test) === -1){
                setSelectedTests([test, ...selectedTests]);
                setTestsIds([test._id, ...testsIds]);
                setSelectTest({})
            }
        }
        
        addTest();

   }, [selectTest, selectedTests, testsIds, tests])



   useEffect(() => {
    const loadAssessmentType = async () => {
        const response = await api.get(`/admin/assessment_types/${_id}`);
        const assessmentType = response.data[0];
        setName(assessmentType.name);
        setDescription(assessmentType.description);
        setType(assessmentType.type)
        setSelectedTests(assessmentType.tests);
        setTestsIds(assessmentType.testsIds);
        setPicture(assessmentType.picture);
        setLesion(assessmentType.lesion)
    }
    loadAssessmentType();

    }, [_id])

    
    const removeTest = (test) => {

        const testsEditado = [...selectedTests];
        testsEditado.splice(selectedTests.indexOf(test), 1)
        setSelectedTests(testsEditado);

        const testsIdsEditado = [...testsIds];
        testsIdsEditado.splice(testsIds.indexOf(test._id), 1)
        setTestsIds(testsIdsEditado);

    }

    const editType = async () => {
        if(!validate() && !isLoading){return};

        setIsLoading(true);

        try{
            await api.put('/admin/assessment_types', {_id, name, description, testsIds: JSON.stringify(testsIds), picture, type: lesion ? 'lesion' : type});
            setIsLoading(false);
            setMsg({message:'Tipo de avaliação editado com sucesso.', type: 'success'});

        }catch(error){
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({message:msg, type: 'error'});
                return;
              }
            setMsg({message: 'Falha de conexão.', type: 'error'});
        }
    }

    function validate(){
        if(!name){
            setMsg({message: 'Preencha o campo nome do tipo', type: 'warning'});
            return false;
        }

        if(!type){
            setMsg({message: 'Selecione um tipo', type: 'warning'});
            return false;
        }

        return true;
    }


    const removeType = async () => {
        var response = window.confirm("Tem certeza que quer remover esse tipo de avaliação?");
        if(response === true){
            await api.delete(`/admin/assessment_types/${_id}`);
            history.goBack();
        }
    }


    return (
        <Page name="assessment-types">
            <h1>
                Adicionar Tipo
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={()=> history.goBack() } />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={()=> removeType() } />
            </h1>


            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaInfo">


            <div className="container cardAvatar">
                 <div className="areaAvatar">
                    
                    <Avatar image={picture} size={100} icon="cloud-upload" /> 

                    <ImageUploading
                    acceptType={['jpg', 'gif', 'png']}
                    resolutionType="ratio"
                    multiple={false}
                    onChange={(image) => {
                        setPicture(image[0].data_url);
                    }}
                    dataURLKey="data_url"
                    >
                        {({ onImageUpload }) => (
                            <BtnLink label="Editar Imagem" handleOnClick={onImageUpload}/>
                        )}
                    </ImageUploading>

                </div>
            </div>
            
            <div className="container cardInfo">

                <div className="informacoes">
                <h3>Informações Básicas</h3>   

<table className="uk-table uk-table-striped uk-table-small uk-table-middle">
   <thead>
       <tr>
           <th>Formato</th>
           <th width="40">#</th>
       </tr>
   </thead>
   <tbody>

       <tr>
           <td>Avaliação do tipo lesão?</td>
           <td><Switch name={"lesion"} checked={lesion} onChange={(e) => setLesion(e.target.checked)} /></td>
       </tr>


   </tbody>
</table>
                
<Fieldset label="Nome" type="text" placeholder="Informe o seu nome" autofocus={true} handleValue={name} handleOnChange={(e) => setName(e.target.value)}/>
<Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o tipo de avaliação" multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)}/>


{!lesion && 
   <FieldSelect label="Tipo" placeholder="Selecione um tipo" handleValue={type} handleOnChange={(e) => setType(e.target.value)}>
       <option value="">Selecione um tipo</option>
       <option value="football">Futebol</option>
       <option value="crossfit">Crossfit</option>
       <option value="running">Corrida</option>
       <option value="volleyball">Voley</option>
       <option value="basketball">Basquete</option>
       <option value="fight">Luta</option>
       <option value="workout">Workout</option>
       <option value="gymnastic">Ginastica</option>
       <option value="default">Default</option>
   </FieldSelect>
}

</div>

                </div>
                
                <div className="container cardTests">
                <h3>Relação de Testes</h3>                    
                <div className="areaAddTest">
                    <FieldSelect  placeholder="Selecione um tipo" handleValue={selectTest} handleOnChange={(e) => setSelectTest(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            tests.map((test, index) => (
                                <option key={index} value={index}>{test.name}</option>
                            ))
                        }
                    </FieldSelect>
                </div>
                
                {
                    selectedTests.length > 0 &&
                    <>
                        <div className="areaThumbsTest">
                            {
                                selectedTests.map((test, index) => (
                                    <CardTest test={test} key={index} handleOnDelete={()=>removeTest(test)}  />
                                ))
                            }
                        </div>
                    </>
                }

            </div>

            </div>


            

            <div className="areaBtnTest">
                <Button label="Editar Tipo" isLoading={isLoading} handleOnClick={editType} handleType="submit" />
            </div>

           
            
       </Page>
    )
}
