import React, {useState} from 'react';
import Page from 'components/Templates/Page';
import BtnTitle from 'components/BtnTitle';
import Msg from 'components/Msg';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory } from "react-router-dom";

import './style.css';

export default function AddPlanPagarme() {
    const history = useHistory();

    const [amount, setAmount] = useState('');
    const [days, setDays] = useState('30');
    const [name, setName] = useState('');
    const [trial_days, setTrial_days] = useState('0');

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({message: '', type: ''});
    
    const addPlan = async (e) => {
        e.preventDefault();

        if(!validate() && !isLoading){return};

        setIsLoading(true);

        try{
            await api.post('/admin/pagarme/plans', {name, amount, days, trial_days});
            setMsg({message:'Plano adicionado na Pagar.me com sucesso.', type: 'success'});
            setAmount('');
            setDays('');
            setName('');
            setTrial_days('');
            setIsLoading(false);

        }catch(error){
            console.log(error)
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({message:msg, type: 'error'});
                return;
              }
            setMsg({message: 'Falha de conexão.', type: 'error'});
        }
    }

    function validate(){
        
        if(!name){
            setMsg({message: 'Digite um nome para o plano.', type: 'warning'});
            return false;
        }

        if(!amount){
            setMsg({message: 'Informe o valor do plano.', type: 'warning'});
            return false;
        }

        if(!days){
            setMsg({message: 'Informe o prazo em dias, para cobrança das parcelas.', type: 'warning'});
            return false;
        }

    
        return true;
    }


    return (
        <Page name="pagarme">
            <h1>PagarMe - Novo Plano 
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={()=> history.goBack() } />
            </h1>
            <div className="container areaInfo areaForm">
                
                <form onSubmit={addPlan} className="informacoes">
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o plano" autofocus={true} handleValue={name} handleOnChange={(e) => setName(e.target.value)}/>

                    <Fieldset label="Valor" type="text" placeholder="Informe o valor do plano"  handleValue={amount} handleOnChange={(e) => setAmount(e.target.value)} obs="Valor que será cobrado recorrentemente (em centavos). Ex: R$49,90 = 4990"/>


                    
                    <Fieldset label="Dias" type="text" placeholder="Prazo, em dias, para cobrança das parcelas" handleValue={days} handleOnChange={(e) => setDays(e.target.value)} obs="Prazo, em dias, para cobrança das parcelas"/>
                    <Fieldset label="Dias Trial" type="text" placeholder="Dias para teste gratuito." handleValue={trial_days} handleOnChange={(e) => setTrial_days(e.target.value)} obs="default: 0 Dias para teste gratuito do produto. Valor começará a ser cobrado no dia trial_days + 1"/>

                    { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}
                    <div className="areaBtn">
                        <Button label="Adicionar" isLoading={isLoading} handleOnClick={addPlan} handleType="submit" />
                    </div>
                </form>
            </div>


       </Page>
    )
}
