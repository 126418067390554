import React, {useState} from 'react';

import Page from 'components/Templates/Page';

import Msg from 'components/Msg';

import BtnTitle from 'components/BtnTitle';

import Fieldset from 'components/Fieldset';
import Button from 'components/Button';

import SelectIdiomas from 'components/SelectIdiomas';

import api from 'services/api';
import { useHistory } from "react-router-dom";

// import './style.css';

export default function AddSetting() {

    const history = useHistory();
    
    const [ termsOfUse, setTermsOfUse ] = useState('');
    const [ language, setLanguage ] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({message: '', type: ''});
    

    const addSettings = async () => {
        
        if(!validate() && !isLoading){return};

        setIsLoading(true);

        try{
            await api.post('/admin/settings', {termsOfUse, language});
            setIsLoading(false);
            setMsg({message:'Configurações Adicionadas com Sucesso.', type: 'success'});
            setTermsOfUse('');
            setLanguage('');
        }catch(error){
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({message:msg, type: 'error'});
                return;
              }
            setMsg({message: 'Falha de conexão.', type: 'error'});
        }
    }

    function validate(){
        if(!termsOfUse){
            setMsg({message: 'Insira os termos de uso.', type: 'warning'});
            return false;
        }

        if(!language){
            setMsg({message: 'Selecione um idioma.', type: 'warning'});
            return false;
        }

        return true;
    }


    return (
        <Page name="settings">
            <h1>
                Adicionar Configurações
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={()=> history.goBack() } />
            </h1>


            
            
                <div className="container">
                    
                <Fieldset label="Termos de Uso" type="text" placeholder="Insira os termos de uso." multiline={true} rows={8} handleValue={termsOfUse} handleOnChange={(e) => setTermsOfUse(e.target.value)}/>

                <SelectIdiomas handleValue={language} handleOnChange={(e) => setLanguage(e.target.value)}/>


                </div>



            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnTest">
                <Button label="Adicionar Config." isLoading={isLoading} handleOnClick={addSettings} handleType="submit" />
            </div>

           
            
       </Page>
    )
}
