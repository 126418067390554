import React from 'react';
import './style.css';

function TagActive(props) {

    const {label, active } = props;

  return (
    <p className={`tag ${active ? 'tagActive' : 'tagInactive'}`}>
        {label}
    </p>
  );
}

export default TagActive;