import React, { useEffect, useState } from "react";

import Avatar from "components/Avatar";
import { Link } from "react-router-dom";

import "./style.css";
import { format, parseISO } from "date-fns";
import Loading from "components/Loading";
import api from "services/api";
import ButtonExport from "../ButtonExport";

export default function Customers({ filters }) {
  const { type, startDate, endDate } = filters;

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadClients = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/admin/dashboards/${type.filter}`, {
          params: {
            start: startDate,
            end: endDate,
          },
        });
        setCustomers(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    loadClients();
  }, [type, startDate, endDate]);

  return (
    <div className="container">
      <div className="info">
        <h3>{type?.label}</h3>
        <ButtonExport
          type={type.filter}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      {loading && <Loading />}
      {!loading && !customers.length ? (
        <p>Nenhum registro encontrado.</p>
      ) : (
        <div className="table-wrapper">
          <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
            <thead>
              <tr>
                <th width="40">Avatar</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                {type.filter === "subscriptions-canceled" && (
                  <th> Motivo Cancelamento</th>
                )}
                <th>Criado em:</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((client, index) => (
                <tr key={index}>
                  <td>
                    <Avatar
                      name={client.name}
                      image={client.avatar}
                      size={28}
                    />
                  </td>
                  <td>
                    <Link to={`/clients/${client._id}`}>{client.name}</Link>
                  </td>
                  <td>{client.email}</td>
                  <td>{client.phone || "-"}</td>
                  {type.filter === "subscriptions-canceled" && (
                    <td>{client.cancellationReason || "-"}</td>
                  )}
                  <td>
                    {client.createdAt &&
                      format(parseISO(client.createdAt), "dd/MM/yy - HH:mm")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
