import React from 'react';
import FieldSelect from 'components/FieldSelect';

const SelectIdiomas = (props) => {

    const { handleValue, handleOnChange } = props;

    return (
        <FieldSelect label="Idioma" placeholder="Selecione o idioma" handleValue={handleValue} handleOnChange={handleOnChange}>
            <option value="">Selecionar</option>
            <option value="pt">Português</option>
            <option value="en">Inglês</option>
            <option value="es">Espanhol</option>
            <option value="it">Italiano</option>
        </FieldSelect>
    )
}

export default SelectIdiomas;