import axios from 'axios';
import { getToken, isAuthenticated } from './auth'

const baseURL = process.env.REACT_APP_API_BASE_URL; 

const api = axios.create({ baseURL });

api.interceptors.request.use(async (options) => {
  const authenticated = await isAuthenticated();
  if (authenticated) {
    options.headers['x-access-token'] = await getToken()
  }
  return options;
})

api.interceptors.response.use(response => {
  return response.data;
})

api.baseUrl = baseURL;

export default api;