import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';

import Msg from 'components/Msg';

import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';

import Button from 'components/Button';
import { Switch } from 'react-switch-input';
import api from 'services/api';
import { useHistory } from "react-router-dom";

import Voucher from 'voucher-code-generator';

import './style.css';

export default function AddPlan() {

    const history = useHistory();
    
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [free, setFree] = useState(false);
    const [expirationDate, setExpirationDate] = useState('');
    const [coupon, setCoupon] = useState('');

    const [completeAssessment, setcompleteAssessment] = useState(false);
    const [showVideo, setshowVideo] = useState(false);
    const [infoTests, setinfoTests] = useState(false);
    const [customAssessment, setcustomAssessment] = useState(false);
    const [reportCompleteLink, setreportCompleteLink] = useState(false);
    const [reportCompletePdf, setreportCompletePdf] = useState(false);
    const [assymmetryTest, setassymmetryTest] = useState(false);
    const [profileRisk, setprofileRisk] = useState(false);
    const [groups, setgroups] = useState(false);
    const [resulComparative, setresulComparative] = useState(false);
    const [Inclinometer, setInclinometer] = useState(false);
    const [comments, setcomments] = useState(false);

    const [planPublic, setPlanPublic] = useState(false);
    
    const [planAppleId, setPlanAppleId] = useState('');
    const [planAndroidId, setPlanAndroidId] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });




    const addPlan = async () => {

        setIsLoading(true);

        const newRoles = {
            completeAssessment,
            showVideo,
            infoTests,
            customAssessment,
            reportCompleteLink,
            reportCompletePdf,
            assymmetryTest,
            profileRisk,
            groups,
            resulComparative,
            Inclinometer,
            comments,
            planAppleId,
            planAndroidId
        };

        try {
            await api.post('/admin/plans', { planAppleId, name, description, price, free: free.toString(), coupon, expiration_date: expirationDate, roles: newRoles, public: planPublic.toString() });
            setIsLoading(false);
            setMsg({ message: 'Plano adicionado com sucesso.', type: 'success' });
            setName('');
            setDescription('');
            setPrice('');
            setcompleteAssessment(false);
            setshowVideo(false);
            setinfoTests(false);
            setcustomAssessment(false);
            setreportCompleteLink(false);
            setreportCompletePdf(false);
            setassymmetryTest(false);
            setprofileRisk(false);
            setgroups(false);
            setresulComparative(false);
            setInclinometer(false);
            setcomments(false);
            
            setPlanAppleId('');
            setPlanAndroidId('');


        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    // Generate Coupon Code
    useEffect(() => {
        let code = name.slice(0, 3);
        if (free && name.length >= 3) {
            const voucher = Voucher.generate({
                length: 3,
                count: 1,
                prefix: code
            });
            setCoupon(voucher[0].toUpperCase());
        } else {
            setCoupon('')
        }
    }, [name, free])


    return (
        <Page name="assessment-types">
            <h1>
                Adicionar Plano
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="informacoes">

                <div className="container">

                    <table className="uk-table uk-table-striped uk-table-small uk-table-middle">
                        <thead>
                            <tr>
                                <th>Tipo de Plano</th>
                                <th width="40">Ativar?</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Plano Gratuito</td>
                                <td><Switch name={"free"} checked={free} onChange={(e) => setFree(e.target.checked)} /></td>
                            </tr>

                        </tbody>
                    </table>

                    <Fieldset label="Expira em (Opcional)" type="date" placeholder="Informe uma limite para vigência do plano." handleValue={expirationDate} handleOnChange={(e) => setExpirationDate(e.target.value)} />

                </div>

                <div className="container">

                    <h3>Informações</h3>

                    <Fieldset label="Plan Apple ID" type="text" placeholder="Informe o id do plano oferecido pela Apple" handleValue={planAppleId} handleOnChange={(e) => setPlanAppleId(e.target.value)} />

                    <Fieldset label="Plan Android ID" type="text" placeholder="Informe o id do plano oferecido pela Google" handleValue={planAndroidId} handleOnChange={(e) => setPlanAndroidId(e.target.value)} />
                    

                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o plano." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />

                    <Fieldset label="Cupom" type="text" placeholder="Informe uma cupom para o plano." handleValue={coupon} handleOnChange={(e) => setCoupon(e.target.value)} />


                    {!free && <Fieldset label="Valor" type="text" placeholder="Digite o preço do plano" handleValue={price} handleOnChange={(e) => setPrice(e.target.value)} obs="Valor que será cobrado recorrentemente (em centavos). Ex: R$49,90 = 4990" />}

                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o plano." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                </div>

                <div className="container">
                    <h3>Funcionalidades</h3>
                    <table className="uk-table uk-table-striped uk-table-small uk-table-middle">
                        <thead>
                            <tr>
                                <th>Recurso</th>
                                <th width="40">Ativar?</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Avaliações completas</td>
                                <td><Switch name={"completeAssessment"} checked={completeAssessment} onChange={(e) => setcompleteAssessment(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Videos testes</td>
                                <td><Switch name={"showVideo"} checked={showVideo} onChange={(e) => setshowVideo(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Informações e ref dos testes</td>
                                <td><Switch name={"infoTests"} checked={infoTests} onChange={(e) => setinfoTests(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Personalizar Avaliação (Logo própria e endereço)</td>
                                <td><Switch name={"customAssessment"} checked={customAssessment} onChange={(e) => setcustomAssessment(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Relatório Completo em PDF</td>
                                <td><Switch name={"reportCompletePdf"} checked={reportCompletePdf} onChange={(e) => setreportCompletePdf(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Assimetria dos testes</td>
                                <td><Switch name={"assymmetryTest"} checked={assymmetryTest} onChange={(e) => setassymmetryTest(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Perfil de Risco</td>
                                <td><Switch name={"profileRisk"} checked={profileRisk} onChange={(e) => setprofileRisk(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Grupos</td>
                                <td><Switch name={"groups"} checked={groups} onChange={(e) => setgroups(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Resultado Comparativo</td>
                                <td><Switch name={"resulComparative"} checked={resulComparative} onChange={(e) => setresulComparative(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Inclinômetro</td>
                                <td><Switch name={"Inclinometer"} checked={Inclinometer} onChange={(e) => setInclinometer(e.target.checked)} /></td>
                            </tr>

                            <tr>
                                <td>Comentários</td>
                                <td><Switch name={"comments"} checked={comments} onChange={(e) => setcomments(e.target.checked)} /></td>
                            </tr>

                        </tbody>
                    </table>

                </div>


                <div className="container">
                    <h3>Privacidade</h3>
                    <table className="uk-table uk-table-striped uk-table-small uk-table-middle">
                        <thead>
                            <tr>
                                <th>Configuração</th>
                                <th width="40">Ativar?</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Esse plano será visivel para todos os usuarios.</td>
                                <td><Switch name={"planPublic"} checked={planPublic} onChange={(e) => setPlanPublic(e.target.checked)} /></td>
                            </tr>

                        </tbody>
                    </table>

                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Plano" isLoading={isLoading} handleOnClick={addPlan} handleType="submit" />
            </div>



        </Page>
    )
}
