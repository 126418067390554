import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import Search from 'components/Search';
import './style.css';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";


export default function Patients() {

    const [patients, setPatients] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);



    useEffect(() => {
        async function loadPatients() {
            try {
                const response = await api.get(`/admin/patients?page=${page.toString()}`);
                setPatients(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);

            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os patientes.', type: 'error'});
                console.log(error)
            }
        }
        loadPatients();
    }, [page])

    const searching = async (value) => {
        try {
            const response = await api.get(`/admin/patients?search=${value}`);
            setPatients(response.data);
            setIsLoading(false);
            setPaginate(response.paginate);

        } catch (error) {
            setIsLoading(false);
            // setMsg({message: 'Falha ao listar os patientes.', type: 'error'});
            console.log(error)
        }
    }

    return (
        <Page name="patients" loading={isLoading}>

            <div className="headerInterno">
                <h1>Pacientes | <small> <b>{paginate.total}</b> cadastrado(s)</small></h1>
                <div>
                    <Search placeholder="Pesquisar paciente..." handleOnChange={(e) => {
                        searching(e.target.value)
                    }} />
                </div>
            </div>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="40">Avatar</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Telefone</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            patients.map((patient, index) => (
                                <tr key={index}>
                                    <td><Avatar name={patient.name} image={patient.picture} size={28} /></td>
                                    <td><Link to={`/patients/${patient._id}`}>{patient.name}</Link></td>
                                    <td>{patient.email}</td>
                                    <td>{patient.phone || '-'}</td>
                                    <td>{patient.status || '-'}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={true}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />
        </Page>
    )
}
