import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { Time } from 'components/Time';
import { Link } from 'react-router-dom';
import BtnTitle from 'components/BtnTitle';
import { useHistory } from "react-router-dom";
import './style.css';
import Pagination from "react-js-pagination";


export default function AssessmentTypes(props) {

    const [assessmentTypes, setAssessmentTypes] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    let history = useHistory();
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);


    useEffect(() => {
        const loadAssessmentTypes = async () => {
            try {
                const response = await api.get(`/admin/assessment_types?page=${page.toString()}`);
                setAssessmentTypes(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);

                
            } catch (error) {
                setIsLoading(false);
                console.log(error)
            }
        }
        loadAssessmentTypes();
    }, [page])


    return (
        <Page name="assessment-types" loading={isLoading}>
            <h1>
                Tipos de Avaliação
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/assessment-types/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="100">Foto</th>
                            <th>Nome</th>
                            <th>Tipo</th>
                            <th>Nº Testes</th>
                            <th>Duração</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assessmentTypes.map((assessmentType, index) => (
                                <tr key={index}>
                                    <td><img src={assessmentType.picture || 'assets/images/placeholder_phast.png'} className="thumb" alt="" /></td>
                                    <td><Link to={`/assessment-types/edit/${assessmentType._id}`}>{assessmentType.name}</Link></td>
                                    <td>{assessmentType.type}</td>
                                    <td><span className="badge">{assessmentType.tests.length}</span></td>
                                    <td><span className="time"><i className='bx bx-time'></i><Time seconds={assessmentType.duration} /></span></td>
                                </tr>
                            ))
                        }
                    </tbody>
                    
                </table>

            </div>
            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={parseInt(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={true}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />
        </Page>
    )
}
