import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Páginas
import Dashboard from './../pages/Dashboard';
import Clients from './../pages/Clients/ListClients';
import ShowClient from './../pages/Clients/ShowClient';

import Patients from './../pages/Patients/ListPatients';
import ShowPatient from './../pages/Patients/ShowPatient';

import AssessmentTypes from './../pages/AssessmentTypes/ListAssessmentType';
import AddAssessmentTypes from './../pages/AssessmentTypes/AddAssessmentType';
import EditAssessmentTypes from './../pages/AssessmentTypes/EditAssessmentType';
import Assessments from './../pages/Assessments';
import PlansPagarme from './../pages/Pagarme/Plans/List';
import AddPlansPagarme from './../pages/Pagarme/Plans/Add';
import Plans from './../pages/Plans/ListPlans';
import AddPlans from './../pages/Plans/AddPlans';
import EditPlan from './../pages/Plans/EditPlan';
import Questions from './../pages/Questions/ListQuestions';
import AddQuestion from './../pages/Questions/AddQuestion';
import EditQuestion from './../pages/Questions/EditQuestion';
import Logs from './../pages/Logs';
import Users from './../pages/Users/ListUsers';
import AddUser from './../pages/Users/AddUser';
import EditUser from './../pages/Users/EditUser';
import MyAccount from './../pages/MyAccount';
import UpdatePassword from './../pages/UpdatePassword';
import Settings from './../pages/Settings/ListSettings';
import AddSetting from './../pages/Settings/AddSetting';
import EditSetting from './../pages/Settings/EditSetting';

export default function Private(props){
    const {roles} = props;
    return (
        <Router>
            <Switch>
                {roles.dashboard && <Route exact path='/dashboard' component={Dashboard} />}
                {roles.clients && <Route exact path='/clients' component={Clients} />}
                {roles.clients && <Route exact path='/clients/:_id' component={ShowClient} />}
                
                {roles.patients && <Route exact path='/patients' component={Patients} />}
                {roles.patients && <Route exact path='/patients/:_id' component={ShowPatient} />}
                
                {roles.assessments && <Route exact path='/assessments' component={Assessments} />}
                {roles.typesAssessments && <Route exact path='/assessment-types' component={AssessmentTypes} />}
                {roles.typesAssessments && <Route exact path='/assessment-types/add' component={AddAssessmentTypes} />}
                {roles.typesAssessments && <Route exact path='/assessment-types/edit/:_id' component={EditAssessmentTypes} />}
                {roles.pagarme && <Route exact path='/pagarme/plans' component={PlansPagarme} />}
                {roles.pagarme && <Route exact path='/pagarme/plans/add' component={AddPlansPagarme} />}
                {roles.plans && <Route exact path='/plans' component={Plans} />}
                {roles.plans && <Route exact path='/plans/add' component={AddPlans} />}
                {roles.plans && <Route exact path='/plans/edit/:_id' component={EditPlan} />}
                {roles.faq && <Route exact path='/questions' component={Questions} />}
                {roles.faq && <Route exact path='/questions/add' component={AddQuestion} />}
                {roles.faq && <Route exact path='/questions/edit/:_id' component={EditQuestion} />}
                {roles.logs && <Route exact path='/logs' component={Logs} />}
                {roles.users && <Route exact path='/users' component={Users} />}
                {roles.users && <Route exact path='/users/add' component={AddUser} />}
                {roles.users && <Route exact path='/users/edit/:_id' component={EditUser} />}
                {roles.settings && <Route exact path='/settings' component={Settings} />}
                {roles.settings && <Route exact path='/settings/add' component={AddSetting} />}
                {roles.settings && <Route exact path='/settings/edit/:_id' component={EditSetting} />}

                <Route exact path='/my-account' component={MyAccount} />
                <Route exact path='/update-password' component={UpdatePassword} />
                <Route path='/*' component={Dashboard} />
            </Switch>
        </Router>
    )
}