import React, { useLayoutEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { isAuthenticated, getAuthUser } from 'services/auth';
import Private from './private';
import Public from './public';

export default function Routes() {
    const logged = useSelector(state => state.loginState.isLogged);
    const [ isLogged, setIsLogged] = useState(false);
    const [userLogged, setUserLogged] = useState({});

    useLayoutEffect(() => {
        async function isAtuh(){
            const auth = await isAuthenticated();
            const user = await getAuthUser();
            setUserLogged(user);
            setIsLogged(auth);
        }
        isAtuh();
    }, [logged, isLogged])
  
    return isLogged ? <Private roles={userLogged?.rolesAdmin} /> : <Public />;
        
  }
  

