import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';

export default function Plans() {
    const history = useHistory();
    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const loadPlans = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/plans`);
                setPlans(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os plans.', type: 'error'});
            }
        }
        loadPlans();
    }, []);

    return (
        <Page name="plans" loading={isLoading}>
            <h1>Planos de Phast
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/plans/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Valor</th>
                            <th>Criado em:</th>
                            <th>Expira em:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            plans.map((plan, index) => (
                                <tr key={index}>
                                    <td><Link to={`/plans/edit/${plan._id}`}>{plan.name}</Link></td>
                                    <td>{plan.description}</td>
                                    <td>{plan.free ? 'Gratuito' : (plan.price / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(plan.createdAt), 'dd/M/Y')}</td>
                                    <td>{plan.expiration_date && <><i className='bx bx-calendar'></i>{format(parseISO(plan.expiration_date), 'dd/M/Y')}</>}</td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>

            </div>


        </Page>
    )
}
