import React from 'react';
import './style.css';

const Fieldset = (props) => {
  const { type, placeholder, handleValue, handleOnChange } = props;
  return (
    <div className="areaSearch">
      <i className='bx bx-search'></i>
      <input type={type} placeholder={placeholder} className="campoSearch" value={handleValue} onChange={handleOnChange} />
    </div>
  );
}

export default Fieldset;