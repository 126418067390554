import React, {useState, useEffect} from 'react';
import { parseISO, format } from 'date-fns';
import BtnTitle from 'components/BtnTitle';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory } from "react-router-dom";

export default function PlansPagarme() {

    const [plans, setPlans] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    let history = useHistory();

    useEffect(() => {
        const loadPlans = async () => {
            setIsLoading(true);
            try{
                const response = await api.get(`admin/pagarme/plans`);
                setPlans(response);
                setIsLoading(false);
            }catch(error){
                setIsLoading(false);                
            }
        }
        loadPlans();
    }, [])

    return (
        <Page name="pagarme" loading={isLoading}>
            
            <h1>
                 PagarMe - Planos
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={()=> history.push('/pagarme/plans/add') } />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Dias</th>
                        <th>Trial</th>
                        <th>Valor</th>
                        <th>Criado em:</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        plans.map((plan, index) => (
                            <tr key={index}>
                                <td>{plan.name}</td>
                                <td>{plan.days}</td>
                                <td>{plan.trial_days}</td>
                                <td>{(plan.amount / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                <td><i className='bx bx-calendar'></i>{format(parseISO(plan.date_created), 'dd/M/Y')}</td>
                            </tr>
                        ))
                    }
                </tbody>
                </table>

            </div>
            
       </Page>
    )
}
