import React, { useState, forwardRef } from "react";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "./style.css";
import withClickOutside from "hooks/withClickOutside";

const Calendar = forwardRef(({ open, setOpen, ...props }, ref) => {
  const [state, setState] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
    },
  ]);

  function handleChange() {
    setOpen((data) => !data);
  }
  function handleDatePickerChange(item) {
    setState([item.selection]);
  }

  function handleClick() {
    props.onFilter(state);
  }

  const buttonText =
    format(state[0].startDate, "dd/MM/yyyy") +
    " - " +
    format(state[0].endDate, "dd/MM/yyyy");
  return (
    <div className="wrapper" ref={ref}>
      <div className="input-group">
        <button className="toggle-calendar" onClick={() => handleChange()}>
          <box-icon name="filter-alt"></box-icon>
          {buttonText}
        </button>
        <button className="btn-filter" onClick={handleClick}>
          OK
        </button>
      </div>

      {open && (
        <div className="rangepicker my-4">
          <DateRangePicker
            onChange={handleDatePickerChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
            className="calendar"
            locale={ptBR}
          />
        </div>
      )}
    </div>
  );
});

export default withClickOutside(Calendar);
