import React, { useState } from 'react'
import api from 'services/api';

import './style.css'
import Button from 'components/Button';


export default function ButtonExport({type, startDate, endDate}) {
  const [loading, setLoading] = useState(false);


  async function exportToCsv() {
    try {
      setLoading(true)
      const response = await api.get(`/admin/dashboards/${type}`, {
        params: {
          type: 'download',
          start: startDate,
          end: endDate
        },
        responseType: 'blob'
      });

      const file = new Blob([response], { type: 'text/csv' });

      const fileUrl = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = `${type}-${startDate}-${endDate}.csv`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(fileUrl);

      document.body.removeChild(link);

    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button handleOnClick={exportToCsv} isLoading={loading} label="Exportar" />
  )
}