import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import './style.css';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import TagActive from 'components/TagActive';
import { parseISO, format } from 'date-fns';
import Search from 'components/Search';

export default function Clients() {

    const [clients, setClients] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);


    useEffect(() => {
        const loadClients = async () => {
            try {
                const response = await api.get(`/admin/clients?page=${page.toString()}`);
                setClients(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);

            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os clientes.', type: 'error'});
                console.log(error)
            }
        }
        loadClients();
    }, [page])

    const searching = async (value) => {
        try {
            const response = await api.get(`/admin/clients?search=${value}`);
            setClients(response.data);
            setIsLoading(false);
            setPaginate(response.paginate);

        } catch (error) {
            setIsLoading(false);
            // setMsg({message: 'Falha ao listar os clientes.', type: 'error'});
            console.log(error)
        }
    }

    return (
        <Page name="clients" loading={isLoading}>


            <div className="headerInterno">
                <h1>Clientes | <small> <b>{paginate.total}</b> cadastrado(s)</small></h1>
                <div>
                    <Search placeholder="Pesquisar cliente..." handleOnChange={(e) => {
                        searching(e.target.value)
                    }} />
                </div>
            </div>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="40">Avatar</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Telefone</th>
                            <th>Criado em:</th>
                            <th width="120">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clients.map((client, index) => (
                                <tr key={index}>
                                    <td><Avatar name={client.name} image={client.avatar} size={28} /></td>
                                    <td><Link to={`/clients/${client._id}`}>{client.name}</Link></td>
                                    <td>{client.email}</td>
                                    <td>{client.phone || '-'}</td>
                                    <td>{client.createdAt && format(parseISO(client.createdAt), 'dd/MM/yy - HH:mm')}</td>
                                    <td><TagActive label={client.active ? 'Ativo' : 'Bloqueado'} active={client.active} /></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={false}
                hideFirstLastPages={false}
                onChange={(page) => {
                    setPage(page);
                }}
            />

        </Page>
    )
}
