import React, { useState, useEffect } from 'react';


import Page from 'components/Templates/Page';


import Avatar from 'components/Avatar';
import BtnTitle from 'components/BtnTitle';
import { parseISO, format } from 'date-fns';
import TagActive from 'components/TagActive';
import BtnIcon from 'components/BtnIcon';

import { useHistory, useParams, Link } from "react-router-dom";

import api from 'services/api';

import './style.css';

export default function ShowPatient() {


    const history = useHistory();
    const { _id } = useParams();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState('');

    const [phone, setphone] = useState('');
    const [anamnese, setanamnese] = useState({});
    const [patientActivities, setpatientActivities] = useState([]);
    const [patientInjuries, setpatientInjuries] = useState([]);
    const [patientAssessments, setpatientAssessments] = useState([]);
    const [patientGroups, setpatientGroups] = useState([]);

    const [group, setGroup] = useState({});
    const [showModalGroup, setShowModalGroup] = useState(false);

    const [injury, setInjury] = useState({});
    const [showModalInjury, setShowModalInjury] = useState(false);

    const [activity, setActivity] = useState({});
    const [showModalActivity, setShowModalActivity] = useState(false);



    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadUser = async () => {
            setIsLoading(true);
            let response = await api.get(`/patients/${_id}`)
            let patient = response.data;
            setName(patient.name);
            setEmail(patient.email);
            setAvatar(patient.picture);
            setphone(patient.phone);
            setanamnese(patient.anamnese);
            setpatientActivities(patient.patientActivities);
            setpatientInjuries(patient.patientInjuries);
            setpatientAssessments(patient.patientAssessments);
            setpatientGroups(patient.patientGroups);
            setIsLoading(false);
        }
        loadUser();
    }, [_id])


    const showGrupo = async (id) => {
        let response = await api.get(`/groups/${id}`)
        setGroup(response.data);
        setShowModalGroup(true);
    }

    const showInjury = async (id) => {
        let response = await api.get(`patient/${_id}/injuries/${id}`)
        setInjury(response.data);
        setShowModalInjury(true);
    }

    const showActivity = async (id) => {
        let response = await api.get(`patient/${_id}/activities/${id}`)
        setActivity(response.data);
        setShowModalActivity(true);
    }


    return (
        <Page name="my-account" isLoading={isLoading}>
            <h1>Paciente
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>

            <div className="areaInfo">

                <div className="container cardAvatar">
                    <div className="areaAvatar">
                        <Avatar name={name} image={avatar} size={100} />
                    </div>
                </div>

                <div className="areaInformacoes">

                    <div className="informacoes">

                        <div className="container">
                            <h3>Informações Básicas</h3>
                            <div className="areaColunas">
                                <h4><small>Nome:</small><br />{name}</h4>
                                <h4><small>Email:</small><br />{email}</h4>
                                <h4><small>Telefone:</small><br />{phone}</h4>
                            </div>

                        </div>

                        <div className="container">
                            <h3>Anamnese</h3>
                            <div className="areaColunas">
                                <h4><small>Sexo:</small><br />{anamnese.gender ? anamnese.gender : '-'}</h4>
                                <h4><small>Escreve:</small><br />Mão {anamnese.writing_hand ? anamnese.writing_hand : '-'}</h4>
                                <h4><small>Peso:</small><br />{anamnese.weight ? anamnese.weight : '-'}kg</h4>
                                <h4><small>Sono:</small><br />{anamnese.avg_sleep_hours ? anamnese.avg_sleep_hours : '-'} horas</h4>
                                <h4><small>Dominância:</small><br />{anamnese.dominance ? anamnese.dominance : '-'}</h4>
                                <h4><small>Altura:</small><br />{anamnese.height ? anamnese.height : '-'}cm</h4>
                                <h4><small>IMC:</small><br />{anamnese.imc ? anamnese.imc : '-'}</h4>
                                <h4><small>Dieta:</small><br />{anamnese.diet_type ? anamnese.diet_type : '-'}</h4>
                            </div>

                        </div>

                        {/* Atividades Fisicas */}
                        {
                            patientActivities.length > 0 &&
                            <div className="container">
                                <h3>Atvidades Físicas</h3>
                                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th>Atividade</th>
                                            <th>Status</th>
                                            <th width="60">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            patientActivities.map((activity, index) => (
                                                <tr key={index}>
                                                    <td>{activity.type}</td>
                                                    <td>{activity.status}</td>
                                                    <td><BtnIcon icon="more-vertical" handleOnClick={() => showActivity(activity._id)} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }

                        {/* Lesões */}
                        {
                            patientInjuries.length > 0 &&
                            <div className="container">
                                <h3>Histórico de Lesões</h3>
                                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th>Parte do Corpo</th>
                                            <th>Membro</th>
                                            <th>Data da Lesão</th>
                                            <th width="60">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            patientInjuries.map((injury, index) => (
                                                <tr key={index}>
                                                    <td>{injury.body_part}</td>
                                                    <td>{injury.body_member}</td>
                                                    <td>{format(parseISO(injury.date_of_injury), 'dd/MM/yy')}</td>
                                                    <td><BtnIcon icon="more-vertical" handleOnClick={() => showInjury(injury._id)} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }


                        {/* Grupos */}
                        {
                            patientGroups.length > 0 &&
                            <div className="container">
                                <h3>Grupos</h3>
                                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th width="30">Imagem</th>
                                            <th>Nome</th>
                                            <th width="60">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            patientGroups.map((group, index) => (
                                                <tr key={index}>
                                                    <td><Avatar name={group.title} image={group.picture} size={28} /></td>
                                                    <td>{group.title}</td>
                                                    <td><BtnIcon icon="more-vertical" handleOnClick={() => showGrupo(group._id)} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }

                        {/* Avaliações */}
                        {
                            patientAssessments.length > 0 &&
                            <div className="container">
                                <h3>Avaliações</h3>
                                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Iniciada Em</th>
                                            <th>Testes</th>
                                            <th>Status</th>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            patientAssessments.map((assessment, index) => (
                                                <tr key={index}>
                                                    <td>{assessment.title}</td>
                                                    <td><i className='bx bx-calendar'></i>{format(parseISO(assessment.createdAt), 'dd/MM/yy')}</td>
                                                    <td><span className="badge">{assessment.testsIds.length}</span></td>
                                                    <td><TagActive label={assessment.completed ? 'Completo' : 'Incompleto'} active={assessment.completed} /></td>
                                                    <td className="areaIcons">
                                                        <a href={`${api.baseUrl}/reports/default/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="link" size={18} /></a>
                                                        <a href={`${api.baseUrl}/reports/print/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="file-text" size={18} /></a>
                                                        <a href={`${api.baseUrl}/reports/pdf/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="file-pdf" size={18} /></a>
                                                    </td>

                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }


                    </div>
                </div>



            </div>

            {/* Modal Grupo */}
            {
                showModalGroup &&
                <div className="modal">
                    <div className="container animated bounceInUp">
                        <h3>{group.title} <BtnIcon icon="close" handleOnClick={() => setShowModalGroup(false)} /></h3>
                        <p><small>{group.description}</small></p>
                        <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                            <thead>
                                <tr>
                                    <th width="40">Avatar</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    group.patients.map((patient, index) => (
                                        <tr key={index}>
                                            <td><Avatar name={patient.name} image={patient.picture} size={28} /></td>
                                            <td><Link to={`/patients/${patient._id}`}>{patient.name}</Link></td>
                                            <td>{patient.email}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            }


            {/* Modal Lesão */}
            {
                showModalInjury &&
                <div className="modal">
                    <div className="container animated bounceInUp">
                        <h3><span>Lesão</span> <BtnIcon icon="close" handleOnClick={() => setShowModalInjury(false)} /></h3>
                        <div className="areaColunas">

                            <h4><small>Local</small><br /> {injury.body_part}</h4>
                            <h4><small>Membro Lesionado</small><br /> {injury.body_member}</h4>
                            <h4><small>Estrutura</small><br /> {injury.structure}</h4>
                            <h4><small>Mecanismo</small><br /> {injury.mechanism}</h4>
                            <h4><small>Tipo</small><br /> {injury.type}</h4>
                            <h4><small>Lesão por Contato?</small><br /> {injury.trauma ? 'Sim' : 'Não'}</h4>
                            <h4><small>Fez Cirurgia?</small><br /> {injury.surgery ? 'Sim' : 'Não'}</h4>
                            {injury.surgery && <h4><small>Data da Cirurgia</small><br /> {injury.date_of_injury}</h4>}
                            <h4><small>Tempo de Afastamento</small><br /> {injury.time_off ? `${injury.time_off} dias` : '-'}</h4>
                            <h4><small>Tempo de Imobilização</small><br /> {injury.immobilization_time ? `${injury.immobilization_time} dias` : '-'}</h4>
                            <h4><small>Tempo de Fisioterapia</small><br /> {injury.physiotherapy_time ? `${injury.physiotherapy_time} dias` : '-'}</h4>
                            <h4><small>Nível da Dor</small><br /> {injury.pain_level ? injury.pain_level : '-'}</h4>
                            <h4><small>Observações</small><br /> {injury.observations ? injury.observations : '-'}</h4>

                        </div>

                    </div>
                </div>
            }



            {/* Modal Atividade */}
            {
                showModalActivity &&
                <div className="modal">
                    <div className="container animated bounceInUp">
                        <h3><span>{activity.type}</span> <BtnIcon icon="close" handleOnClick={() => setShowModalActivity(false)} /></h3>
                        <div className="areaColunas">
                            <h4><small>Início da Prática:</small><br />{format(parseISO(activity.start), 'dd/MM/yy')}</h4>
                            <h4><small>Nº de Competições:</small><br />{activity.competition ? activity.competition : '-'}</h4>
                            <h4><small>Status:</small><br />{activity.active ? 'Ativo' : 'Inativo'}</h4>
                            <h4><small>Observações:</small><br />{activity.observations ? activity.observations : 'Sem observações'}</h4>
                        </div>
                    </div>
                </div>
            }


        </Page>
    )
}
