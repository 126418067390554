import React, {useState, useEffect} from 'react';

import Page from 'components/Templates/Page';

import Msg from 'components/Msg';

import BtnTitle from 'components/BtnTitle';

import Fieldset from 'components/Fieldset';
import Button from 'components/Button';

import api from 'services/api';
import { useHistory, useParams } from "react-router-dom";
import SelectIdiomas from 'components/SelectIdiomas';


// import './style.css';

export default function EditSetting() {

    const {_id} = useParams();

    const history = useHistory();
    
    const [ termsOfUse, setTermsOfUse ] = useState('');
    const [ language, setLanguage ] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({message: '', type: ''});
    

    const editSettings = async () => {
        
        if(!validate() && !isLoading){return};

        setIsLoading(true);

        try{
            await api.put('/admin/settings', {termsOfUse, language, _id});
            setIsLoading(false);
            setMsg({message:'Configurações Adicionadas com Sucesso.', type: 'success'});
            setTermsOfUse('');
            setLanguage('');
        }catch(error){
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({message:msg, type: 'error'});
                return;
              }
            setMsg({message: 'Falha de conexão.', type: 'error'});
        }
    }

    useEffect(() => {   
        const loadSetting = async () => {
            let response = await api.get(`/admin/settings/${_id}`)
            let setting = response.data;
            setTermsOfUse(setting.termsOfUse);
            setLanguage(setting.language);
        }
        loadSetting();
    }, [_id])



    const removeSetting = async () => {
        var response = window.confirm("Tem certeza que quer remover essa configuração?");
        if(response === true){
            await api.delete(`/admin/settings/${_id}`);
            history.goBack();
        }
    }

    function validate(){
        if(!termsOfUse){
            setMsg({message: 'Insira os termos de uso.', type: 'warning'});
            return false;
        }

        if(!language){
            setMsg({message: 'Selecione um idioma.', type: 'warning'});
            return false;
        }

        return true;
    }

    return (
        <Page name="settings">
            <h1>
                Editar Configurações
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={()=> history.goBack() } />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={()=> removeSetting() } />
            </h1>
            
                <div className="container">
                    
                <Fieldset label="Termos de Uso" type="text" placeholder="Insira os termos de uso." multiline={true} rows={8} handleValue={termsOfUse} handleOnChange={(e) => setTermsOfUse(e.target.value)}/>
                <SelectIdiomas handleValue={language} handleOnChange={(e) => setLanguage(e.target.value)}/>


                </div>



            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnTest">
                <Button label="Adicionar Config." isLoading={isLoading} handleOnClick={editSettings} handleType="submit" />
            </div>

           
            
       </Page>
    )
}
