import React from 'react';

import './style.css';

function CardTest(props) {
    const {test, handleOnDelete} = props;
  return (
    <div className="cardTest">
        <div className="thumbTest" style={{backgroundImage: `url(${test.picture})`}}></div>
        <div className="infoTest">
            <h4>{test?.name}<br/> 
            <small>
                {test?.duration && <span><b>Duração:</b> {test?.duration}</span>}
                <span><b>Tipo:</b>{test?.type}</span></small>
            </h4>
        </div>
        <div className="areaBtnTrash" onClick={handleOnDelete}>
            <span uk-icon="trash"></span>
        </div>
    </div>
  );
}

export default CardTest;