import React, { useState, useEffect, useCallback } from 'react';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import { parseISO, format, startOfMonth } from 'date-fns';
import TagActive from 'components/TagActive';
import BtnIcon from 'components/BtnIcon';
import { Link } from "react-router-dom";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './style.css';
import Calendar from 'components/Calendar';
import Customers from './Customers';

const filterableItems = [
    {
        label: 'Usuários ativos',
        key: 'totalActiveUsers',
        filter: 'active-users'
    },
    {
        label: 'Assinaturas recorrentes',
        key: 'totalRenewedSubscriptions',
        filter: 'renewed-subscriptions'
    },
    {
        label: 'Novas assinaturas',
        key: 'totalNewSubscriptions',
        filter: 'new-subscriptions'
    },
    {
        label: 'Assinaturas canceladas',
        key: 'totalSubscriptionsCanceled',
        filter: 'subscriptions-canceled'
    },

]

export default function Dashboard() {

    const [dashboard, setDashboard] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [customerFilterType, setCustomerFilterType] = useState(null)
    const [selection, setSelection] = useState([
        {
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const loadDasboard = useCallback(async () => {
        try {
            setIsLoading(true)

            const dateRange = selection[0]
            const response = await api.get(`/admin/dashboards`, {
                params: {
                    start: format(dateRange.startDate, 'yyyy-MM-dd'),
                    end: format(dateRange.endDate, 'yyyy-MM-dd')
                }
            });
            setDashboard(response);
        } catch (error) {
            setIsLoading(false);
            // setMsg({message: 'Falha ao listar os logs.', type: 'error'});
        } finally {
            setIsLoading(false);
        }
    }, [selection])

    useEffect(() => {
        loadDasboard();
    }, [loadDasboard]);

    function handleDateRangePicker(item) {
        setSelection(item)
        setCustomerFilterType(null)
    }


    return (
        <Page name="dashboard" loading={isLoading}>
            <div className="dashboard-header">
                <h1>Dashboard</h1>
                <Calendar
                    startDate={selection[0].startDate}
                    endDate={selection[0].endDate}
                    onFilter={handleDateRangePicker}
                />
            </div>


            <div className="cardDashs">
                <div className="container cardDash">
                    <h3>Usuários</h3>
                    <h1>{dashboard.totalUsers}<br /><small>Total Cadastrados</small></h1>
                </div>
                <div className="container cardDash">
                    <h3>Avaliações</h3>
                    <h1>{dashboard.totalAssessments}<br /><small>Total Realizadas</small></h1>
                </div>
                <div className="container cardDash">
                    <h3>Pacientes</h3>
                    <h1>{dashboard.totalPatients}<br /><small>Total Cadastrados</small></h1>
                </div>
                <div className="container cardDash">
                    <h3>Logs</h3>
                    <h1>{dashboard.totalLogs}<br /><small>Total registrados</small></h1>
                </div>

                {filterableItems.map(item => (
                    <div
                        key={item.key}
                        onClick={() => setCustomerFilterType(item)}
                        className={'container cardDash filterable ' + (customerFilterType?.key === item.key ? 'active' : '')}>
                        <h3>{item.label}</h3>
                        <h1>{dashboard[item.key]}<br /><small>Total do período</small></h1>
                        <div className="icon">
                            <box-icon size="lg" type="solid" name="file-export"></box-icon>
                        </div>
                    </div>
                )) }
            </div>

            <br />

            {customerFilterType?.key && (
                <div className="areaInfo customers">
                    <Customers filters={{
                        type: customerFilterType,
                        startDate: format(selection[0].startDate, 'yyyy-MM-dd'),
                        endDate: format(selection[0].endDate, 'yyyy-MM-dd'),
                    }} />
                </div>
            )}

            <div className="areaInfo">






                <div className="container cardDash">
                    <h3>Avaliações Recentes</h3>
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                        <thead>
                            <tr>
                                <th>Paciente</th>
                                <th>Fisioterapeuta</th>
                                <th>Iniciada Em</th>
                                <th>Status</th>
                                <th width="150">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dashboard?.assessments?.map((assessment, index) => (
                                    <tr key={index}>
                                        <td><div className="trInfo"><Avatar image={assessment.patient[0]?.picture} name={assessment.patient[0]?.name} size={24} /><span>{`${assessment.patient[0]?.name.slice(0, 12)}...`}</span></div></td>
                                        <td><div className="trInfo"><Avatar image={assessment.user[0]?.avatar} name={assessment.user[0]?.name} size={24} /><span>{`${assessment.user[0]?.name.slice(0, 12)}...`}</span></div></td>
                                        <td><i className='bx bx-calendar'></i>{format(parseISO(assessment.createdAt), 'dd/MM/yy')}</td>
                                        <td><TagActive label={assessment.completed ? 'Completo' : 'Incompleto'} active={assessment.completed} /></td>
                                        <td className="areaIcons">
                                            <a href={`${api.baseUrl}/reports/default/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="link" size={18} /></a>
                                            <a href={`${api.baseUrl}/reports/print/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="file-text" size={18} /></a>
                                            <a href={`${api.baseUrl}/reports/pdf/${assessment._id}`} target="_blank" rel="noopener noreferrer"><BtnIcon icon="file-pdf" size={18} /></a>
                                        </td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>

            <br />

            <div className="areaInfo">


                <div className="container cardDash">
                    <h3>Últimos Pacientes</h3>
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                        <thead>
                            <tr>
                                <th width="20">Avatar</th>
                                <th>Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dashboard?.patients?.map((patient, index) => (
                                    <tr key={index}>
                                        <td><Avatar name={patient.name} image={patient.picture} size={28} /></td>
                                        <td><p><Link to={`/patients/${patient._id}`}>{patient.name}</Link><br /><small>{patient.email}</small> </p></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>


                <div className="container cardDash">
                    <h3>Últimos Usuários</h3>
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                        <thead>
                            <tr>
                                <th width="20">#</th>
                                <th>Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dashboard?.users?.map((user, index) => (
                                    <tr key={index}>
                                        <td><Avatar name={user.name} image={user.avatar} size={28} /></td>
                                        <td><p><Link to={`/users/edit/${user._id}`}>{user.name}</Link><br /><small>{user.email}</small></p></td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>



            </div>

            <br />

            <div className="container cardDash">
                <h3>Últimos Logs</h3>
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Usuário</th>
                            <th>Descrição</th>
                            <th>Quando?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dashboard?.logs?.map((log, index) => {

                                return (
                                    <tr key={index}>
                                        <td><Avatar name={log?.user?.name || '?'} image={log?.user?.avatar} size={28} /></td>
                                        <td><p>{log?.user?.name || 'Usuário não identificado'}</p></td>
                                        <td><p>{log.description}</p></td>
                                        <td><p><i className='bx bx-calendar'></i>{format(parseISO(log.createdAt), 'dd/M/Y')} às {format(parseISO(log.createdAt), 'HH:mm')}hrs</p></td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>


        </Page>
    )
}
